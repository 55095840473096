// this file loads all the icons that can be chosen via the icon setting for the widgets that have the icon option (button_widget, usps_widget, html_embed_widget)
// this file is parsed in #available_icons to populate the icon option dropdown
// these icons are loaded on the website side, and in the admin interface

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAccessibleIcon,
  faAccusoft,
  faAdversal,
  faAndroid,
  faAngellist,
  faAppStoreIos,
  faAppStore,
  faApple,
  faAtlassian,
  faBattleNet,
  faBitbucket,
  faBluetooth,
  faCanadianMapleLeaf,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faCentercode,
  faCentos,
  faChromecast,
  faCreativeCommonsBy,
  faDeezer,
  faDocker,
  faDribbble,
  faEarlybirds,
  faEnvira,
  faEvernote,
  faFacebookF,
  faFacebookSquare,
  faFacebook,
  faFly,
  faFortAwesomeAlt,
  faFortAwesome,
  faForumbee,
  faGithubAlt,
  faGooglePlay,
  faGoogle,
  faGripfire,
  faHotjar,
  faInstagramSquare,
  faInstagram,
  faJira,
  faLeanpub,
  faLinkedin,
  faMailchimp,
  faMedium,
  faMicroblog,
  faPagelines,
  faPlaystation,
  faRaspberryPi,
  faReadme,
  faSafari,
  faShopify,
  faSimplybuilt,
  faSlideshare,
  faSourcetree,
  faStackExchange,
  faSuse,
  faTiktok,
  faUnity,
  faWhatsapp,
  faWolfPackBattalion,
  faWpforms,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
  faAddressBook as farAddressBook,
  faAddressCard as farAddressCard,
  faArrowAltCircleDown as farArrowAltCircleDown,
  faArrowAltCircleLeft as farArrowAltCircleLeft,
  faArrowAltCircleRight as farArrowAltCircleRight,
  faBell as farBell,
  faBuilding as farBuilding,
  faCalendarAlt as farCalendarAlt,
  faCalendarCheck as farCalendarCheck,
  faCalendarMinus as farCalendarMinus,
  faCalendarPlus as farCalendarPlus,
  faCalendarTimes as farCalendarTimes,
  faCalendar as farCalendar,
  faChartBar as farChartBar,
  faCheckCircle as farCheckCircle,
  faCheckSquare as farCheckSquare,
  faClipboard as farClipboard,
  faClock as farClock,
  faCommentDots as farCommentDots,
  faComments as farComments,
  faCompass as farCompass,
  faCopyright as farCopyright,
  faDotCircle as farDotCircle,
  faEdit as farEdit,
  faEnvelopeOpen as farEnvelopeOpen,
  faEnvelope as farEnvelope,
  faEye as farEye,
  faFileAlt as farFileAlt,
  faFileImage as farFileImage,
  faFilePdf as farFilePdf,
  faFile as farFile,
  faFlag as farFlag,
  faFutbol as farFutbol,
  faGrinAlt as farGrinAlt,
  faGrinBeam as farGrinBeam,
  faGrinHearts as farGrinHearts,
  faGrinSquint as farGrinSquint,
  faGrinStars as farGrinStars,
  faGrin as farGrin,
  faHandPointLeft as farHandPointLeft,
  faHandPointRight as farHandPointRight,
  faHandPointUp as farHandPointUp,
  faHandPointer as farHandPointer,
  faHandRock as farHandRock,
  faHandshake as farHandshake,
  faHeart as farHeart,
  faIdCard as farIdCard,
  faImage as farImage,
  faImages as farImages,
  faKissWinkHeart as farKissWinkHeart,
  faLaughBeam as farLaughBeam,
  faLaughSquint as farLaughSquint,
  faLaughWink as farLaughWink,
  faLaugh as farLaugh,
  faLemon as farLemon,
  faLifeRing as farLifeRing,
  faLightbulb as farLightbulb,
  faListAlt as farListAlt,
  faMap as farMap,
  faMoneyBillAlt as farMoneyBillAlt,
  faMoon as farMoon,
  faNewspaper as farNewspaper,
  faObjectUngroup as farObjectUngroup,
  faPaperPlane as farPaperPlane,
  faQuestionCircle as farQuestionCircle,
  faSmileBeam as farSmileBeam,
  faSmileWink as farSmileWink,
  faSmile as farSmile,
  faSnowflake as farSnowflake,
  faSquare as farSquare,
  faStar as farStar,
  faStopCircle as farStopCircle,
  faSun as farSun,
  faThumbsUp as farThumbsUp,
  faTrashAlt as farTrashAlt,
  faUser as farUser,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAd,
  faAddressBook,
  faAddressCard,
  faAirFreshener,
  faAlignCenter,
  faAlignJustify,
  faAllergies,
  faAnchor,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngry,
  faAppleAlt,
  faArchway,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAltH,
  faArrowsAltV,
  faArrowsAlt,
  faAt,
  faAtom,
  faAward,
  faBabyCarriage,
  faBaby,
  faBackward,
  faBacon,
  faBahai,
  faBalanceScaleRight,
  faBalanceScale,
  faBan,
  faBaseballBall,
  faBasketballBall,
  faBath,
  faBatteryFull,
  faBatteryThreeQuarters,
  faBed,
  faBeer,
  faBell,
  faBicycle,
  faBiking,
  faBinoculars,
  faBirthdayCake,
  faBlender,
  faBolt,
  faBone,
  faBookOpen,
  faBookReader,
  faBook,
  faBookmark,
  faBorderStyle,
  faBowlingBall,
  faBoxOpen,
  faBox,
  faBrain,
  faBreadSlice,
  faBriefcase,
  faBroadcastTower,
  faBroom,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBurn,
  faBusAlt,
  faBus,
  faBusinessTime,
  faCalculator,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarMinus,
  faCalendarPlus,
  faCalendarWeek,
  faCalendar,
  faCameraRetro,
  faCamera,
  faCampground,
  faCandyCane,
  faCarAlt,
  faCarBattery,
  faCarCrash,
  faCarSide,
  faCar,
  faCaravan,
  faCaretDown,
  faCaretRight,
  faCaretSquareRight,
  faCarrot,
  faCartPlus,
  faCashRegister,
  faCat,
  faCertificate,
  faChair,
  faChalkboardTeacher,
  faChalkboard,
  faChargingStation,
  faChartArea,
  faChartBar,
  faChartLine,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faCheck,
  faCheese,
  faChessKing,
  faChessKnight,
  faChessQueen,
  faChessRook,
  faChess,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChild,
  faChurch,
  faCircleNotch,
  faCircle,
  faCity,
  faClipboardList,
  faClipboard,
  faClock,
  faCloudDownloadAlt,
  faCloudRain,
  faCloudSunRain,
  faCloudSun,
  faCloudUploadAlt,
  faCloud,
  faCocktail,
  faCoffee,
  faCoins,
  faCommentDots,
  faCommentsDollar,
  faComments,
  faCompactDisc,
  faCompass,
  faCompressAlt,
  faConciergeBell,
  faCookieBite,
  faCookie,
  faCouch,
  faCreditCard,
  faCrow,
  faCrown,
  faCubes,
  faDemocrat,
  faDesktop,
  faDiceFour,
  faDiceTwo,
  faDice,
  faDisease,
  faDog,
  faDollarSign,
  faDolly,
  faDoorClosed,
  faDoorOpen,
  faDotCircle,
  faDove,
  faDownload,
  faDraftingCompass,
  faDragon,
  faDrumSteelpan,
  faDrum,
  faDrumstickBite,
  faDumbbell,
  faDumpster,
  faDungeon,
  faEdit,
  faEgg,
  faEllipsisH,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faEnvelope,
  faEuroSign,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExclamation,
  faExpandAlt,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faEye,
  faFan,
  faFastForward,
  faFaucet,
  faFeatherAlt,
  faFeather,
  faFileAlt,
  faFileContract,
  faFileImage,
  faFileInvoiceDollar,
  faFileInvoice,
  faFilePdf,
  faFileSignature,
  faFile,
  faFilm,
  faFingerprint,
  faFireAlt,
  faFire,
  faFish,
  faFistRaised,
  faFlagCheckered,
  faFlag,
  faFolderOpen,
  faFootballBall,
  faForward,
  faFutbol,
  faGamepad,
  faGem,
  faGhost,
  faGift,
  faGifts,
  faGlassCheers,
  faGlassMartiniAlt,
  faGlassMartini,
  faGlassWhiskey,
  faGlasses,
  faGlobeAmericas,
  faGlobeEurope,
  faGlobe,
  faGolfBall,
  faGreaterThan,
  faGrinBeam,
  faGrinHearts,
  faGrinStars,
  faGrin,
  faGripLinesVertical,
  faGripLines,
  faGuitar,
  faHamburger,
  faHammer,
  faHandHoldingHeart,
  faHandHoldingMedical,
  faHandHoldingUsd,
  faHandHoldingWater,
  faHandHolding,
  faHandPaper,
  faHandPeace,
  faHandPointLeft,
  faHandPointRight,
  faHandPointUp,
  faHandPointer,
  faHandRock,
  faHandSparkles,
  faHandsHelping,
  faHandsWash,
  faHands,
  faHandshake,
  faHatCowboy,
  faHatWizard,
  faHeadSideMask,
  faHeadphones,
  faHeadset,
  faHeart,
  faHelicopter,
  faHiking,
  faHippo,
  faHistory,
  faHome,
  faHorseHead,
  faHorse,
  faHospital,
  faHotTub,
  faHotdog,
  faHotel,
  faHourglassEnd,
  faHourglassHalf,
  faHouseDamage,
  faHouseUser,
  faIceCream,
  faIcons,
  faIdCard,
  faIgloo,
  faInbox,
  faIndustry,
  faInfoCircle,
  faInfo,
  faKey,
  faKiwiBird,
  faLandmark,
  faLanguage,
  faLaptopHouse,
  faLaptop,
  faLaughBeam,
  faLaughSquint,
  faLaugh,
  faLeaf,
  faLevelUpAlt,
  faLifeRing,
  faLightbulb,
  faLink,
  faListAlt,
  faListUl,
  faList,
  faLocationArrow,
  faLockOpen,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltRight,
  faLongArrowAltUp,
  faLuggageCart,
  faMagic,
  faMailBulk,
  faMale,
  faMapMarkedAlt,
  faMapMarked,
  faMapMarkerAlt,
  faMapMarker,
  faMapPin,
  faMapSigns,
  faMap,
  faMedal,
  faMicrophone,
  faMinusCircle,
  faMinus,
  faMobileAlt,
  faMobile,
  faMoneyBillAlt,
  faMoneyBillWaveAlt,
  faMoneyBillWave,
  faMoneyBill,
  faMoneyCheckAlt,
  faMoneyCheck,
  faMonument,
  faMoon,
  faMotorcycle,
  faMountain,
  faMousePointer,
  faMouse,
  faMugHot,
  faMusic,
  faNetworkWired,
  faNewspaper,
  faOtter,
  faPaintBrush,
  faPaperPlane,
  faPaperclip,
  faParachuteBox,
  faParking,
  faPassport,
  faPastafarianism,
  faPause,
  faPaw,
  faPeace,
  faPenAlt,
  faPenFancy,
  faPenNib,
  faPenSquare,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faPeopleArrows,
  faPeopleCarry,
  faPercent,
  faPercentage,
  faPersonBooth,
  faPhoneAlt,
  faPhoneSquareAlt,
  faPhoneSquare,
  faPhoneVolume,
  faPhone,
  faPhotoVideo,
  faPiggyBank,
  faPizzaSlice,
  faPlaceOfWorship,
  faPlaneDeparture,
  faPlane,
  faPlayCircle,
  faPlay,
  faPlug,
  faPlusCircle,
  faPlus,
  faPoo,
  faPowerOff,
  faPrayingHands,
  faPrint,
  faProjectDiagram,
  faPumpSoap,
  faQuestionCircle,
  faQuestion,
  faRainbow,
  faRecycle,
  faReply,
  faRestroom,
  faRibbon,
  faRing,
  faRoad,
  faRoute,
  faRulerCombined,
  faRuler,
  faRunning,
  faSatelliteDish,
  faSchool,
  faScrewdriver,
  faSearchDollar,
  faSearchLocation,
  faSearchPlus,
  faSearch,
  faSeedling,
  faShare,
  faShieldAlt,
  faShip,
  faShippingFast,
  faShoePrints,
  faShoppingBag,
  faShoppingBasket,
  faShoppingCart,
  faShower,
  faShuttleVan,
  faSink,
  faSkating,
  faSkiingNordic,
  faSkiing,
  faSkullCrossbones,
  faSleigh,
  faSmileBeam,
  faSmileWink,
  faSmile,
  faSmokingBan,
  faSnowboarding,
  faSnowflake,
  faSnowman,
  faSoap,
  faSolarPanel,
  faSortAmountUp,
  faSpa,
  faSplotch,
  faSprayCan,
  faSquareFull,
  faSquare,
  faStarOfLife,
  faStar,
  faStepBackward,
  faStickyNote,
  faStopCircle,
  faStop,
  faStore,
  faStreetView,
  faSuitcaseRolling,
  faSuitcase,
  faSun,
  faSwimmer,
  faSwimmingPool,
  faSynagogue,
  faSync,
  faTableTennis,
  faTable,
  faTachometerAlt,
  faTag,
  faTemperatureHigh,
  faTemperatureLow,
  faTheaterMasks,
  faThermometerFull,
  faThermometerHalf,
  faThermometerQuarter,
  faThermometerThreeQuarters,
  faThumbsDown,
  faThumbsUp,
  faTicketAlt,
  faTimes,
  faTint,
  faToiletPaper,
  faToilet,
  faTools,
  faToriiGate,
  faTractor,
  faTrafficLight,
  faTrailer,
  faTrain,
  faTrashAlt,
  faTrash,
  faTree,
  faTrophy,
  faTruckMonster,
  faTruckMoving,
  faTruckPickup,
  faTruck,
  faTshirt,
  faTv,
  faUmbrellaBeach,
  faUnlockAlt,
  faUnlock,
  faUserAlt,
  faUserCheck,
  faUserClock,
  faUserCog,
  faUserFriends,
  faUserNurse,
  faUserPlus,
  faUser,
  faUsers,
  faUtensilSpoon,
  faUtensils,
  faVectorSquare,
  faVial,
  faVideo,
  faViruses,
  faVolleyballBall,
  faVoteYea,
  faWalking,
  faWallet,
  faWarehouse,
  faWater,
  faWeightHanging,
  faWheelchair,
  faWifi,
  faWind,
  faWindowClose,
  faWineBottle,
  faWineGlassAlt,
  faWineGlass,
  faYinYang,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAd,
  faAddressBook,
  faAddressCard,
  faAirFreshener,
  faAlignCenter,
  faAlignJustify,
  faAllergies,
  faAnchor,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngry,
  faAppleAlt,
  faArchway,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAltH,
  faArrowsAltV,
  faArrowsAlt,
  faAt,
  faAtom,
  faAward,
  faBabyCarriage,
  faBaby,
  faBackward,
  faBacon,
  faBahai,
  faBalanceScaleRight,
  faBalanceScale,
  faBan,
  faBaseballBall,
  faBasketballBall,
  faBath,
  faBatteryFull,
  faBatteryThreeQuarters,
  faBed,
  faBeer,
  faBell,
  faBicycle,
  faBiking,
  faBinoculars,
  faBirthdayCake,
  faBlender,
  faBolt,
  faBone,
  faBookOpen,
  faBookReader,
  faBook,
  faBookmark,
  faBorderStyle,
  faBowlingBall,
  faBoxOpen,
  faBox,
  faBrain,
  faBreadSlice,
  faBriefcase,
  faBroadcastTower,
  faBroom,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBurn,
  faBusAlt,
  faBus,
  faBusinessTime,
  faCalculator,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarMinus,
  faCalendarPlus,
  faCalendarWeek,
  faCalendar,
  faCameraRetro,
  faCamera,
  faCampground,
  faCandyCane,
  faCarAlt,
  faCarBattery,
  faCarCrash,
  faCarSide,
  faCar,
  faCaravan,
  faCaretDown,
  faCaretRight,
  faCaretSquareRight,
  faCarrot,
  faCartPlus,
  faCashRegister,
  faCat,
  faCertificate,
  faChair,
  faChalkboardTeacher,
  faChalkboard,
  faChargingStation,
  faChartArea,
  faChartBar,
  faChartLine,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faCheck,
  faCheese,
  faChessKing,
  faChessKnight,
  faChessQueen,
  faChessRook,
  faChess,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChild,
  faChurch,
  faCircleNotch,
  faCircle,
  faCity,
  faClipboardList,
  faClipboard,
  faClock,
  faCloudDownloadAlt,
  faCloudRain,
  faCloudSunRain,
  faCloudSun,
  faCloudUploadAlt,
  faCloud,
  faCocktail,
  faCoffee,
  faCoins,
  faCommentDots,
  faCommentsDollar,
  faComments,
  faCompactDisc,
  faCompass,
  faCompressAlt,
  faConciergeBell,
  faCookieBite,
  faCookie,
  faCouch,
  faCreditCard,
  faCrow,
  faCrown,
  faCubes,
  faDemocrat,
  faDesktop,
  faDiceFour,
  faDiceTwo,
  faDice,
  faDisease,
  faDog,
  faDollarSign,
  faDolly,
  faDoorClosed,
  faDoorOpen,
  faDotCircle,
  faDove,
  faDownload,
  faDraftingCompass,
  faDragon,
  faDrumSteelpan,
  faDrum,
  faDrumstickBite,
  faDumbbell,
  faDumpster,
  faDungeon,
  faEdit,
  faEgg,
  faEllipsisH,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faEnvelope,
  faEuroSign,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExclamation,
  faExpandAlt,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faEye,
  faFan,
  faFastForward,
  faFaucet,
  faFeatherAlt,
  faFeather,
  faFileAlt,
  faFileContract,
  faFileImage,
  faFileInvoiceDollar,
  faFileInvoice,
  faFilePdf,
  faFileSignature,
  faFile,
  faFilm,
  faFingerprint,
  faFireAlt,
  faFire,
  faFish,
  faFistRaised,
  faFlagCheckered,
  faFlag,
  faFolderOpen,
  faFootballBall,
  faForward,
  faFutbol,
  faGamepad,
  faGem,
  faGhost,
  faGift,
  faGifts,
  faGlassCheers,
  faGlassMartiniAlt,
  faGlassMartini,
  faGlassWhiskey,
  faGlasses,
  faGlobeAmericas,
  faGlobeEurope,
  faGlobe,
  faGolfBall,
  faGreaterThan,
  faGrinBeam,
  faGrinHearts,
  faGrinStars,
  faGrin,
  faGripLinesVertical,
  faGripLines,
  faGuitar,
  faHamburger,
  faHammer,
  faHandHoldingHeart,
  faHandHoldingMedical,
  faHandHoldingUsd,
  faHandHoldingWater,
  faHandHolding,
  faHandPaper,
  faHandPeace,
  faHandPointLeft,
  faHandPointRight,
  faHandPointUp,
  faHandPointer,
  faHandRock,
  faHandSparkles,
  faHandsHelping,
  faHandsWash,
  faHands,
  faHandshake,
  faHatCowboy,
  faHatWizard,
  faHeadSideMask,
  faHeadphones,
  faHeadset,
  faHeart,
  faHelicopter,
  faHiking,
  faHippo,
  faHistory,
  faHome,
  faHorseHead,
  faHorse,
  faHospital,
  faHotTub,
  faHotdog,
  faHotel,
  faHourglassEnd,
  faHourglassHalf,
  faHouseDamage,
  faHouseUser,
  faIceCream,
  faIcons,
  faIdCard,
  faIgloo,
  faInbox,
  faIndustry,
  faInfoCircle,
  faInfo,
  faKey,
  faKiwiBird,
  faLandmark,
  faLanguage,
  faLaptopHouse,
  faLaptop,
  faLaughBeam,
  faLaughSquint,
  faLaugh,
  faLeaf,
  faLevelUpAlt,
  faLifeRing,
  faLightbulb,
  faLink,
  faListAlt,
  faListUl,
  faList,
  faLocationArrow,
  faLockOpen,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltRight,
  faLongArrowAltUp,
  faLuggageCart,
  faMagic,
  faMailBulk,
  faMale,
  faMapMarkedAlt,
  faMapMarked,
  faMapMarkerAlt,
  faMapMarker,
  faMapPin,
  faMapSigns,
  faMap,
  faMedal,
  faMicrophone,
  faMinusCircle,
  faMinus,
  faMobileAlt,
  faMobile,
  faMoneyBillAlt,
  faMoneyBillWaveAlt,
  faMoneyBillWave,
  faMoneyBill,
  faMoneyCheckAlt,
  faMoneyCheck,
  faMonument,
  faMoon,
  faMotorcycle,
  faMountain,
  faMousePointer,
  faMouse,
  faMugHot,
  faMusic,
  faNetworkWired,
  faNewspaper,
  faOtter,
  faPaintBrush,
  faPaperPlane,
  faPaperclip,
  faParachuteBox,
  faParking,
  faPassport,
  faPastafarianism,
  faPause,
  faPaw,
  faPeace,
  faPenAlt,
  faPenFancy,
  faPenNib,
  faPenSquare,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faPeopleArrows,
  faPeopleCarry,
  faPercent,
  faPercentage,
  faPersonBooth,
  faPhoneAlt,
  faPhoneSquareAlt,
  faPhoneSquare,
  faPhoneVolume,
  faPhone,
  faPhotoVideo,
  faPiggyBank,
  faPizzaSlice,
  faPlaceOfWorship,
  faPlaneDeparture,
  faPlane,
  faPlayCircle,
  faPlay,
  faPlug,
  faPlusCircle,
  faPlus,
  faPoo,
  faPowerOff,
  faPrayingHands,
  faPrint,
  faProjectDiagram,
  faPumpSoap,
  faQuestionCircle,
  faQuestion,
  faRainbow,
  faRecycle,
  faReply,
  faRestroom,
  faRibbon,
  faRing,
  faRoad,
  faRoute,
  faRulerCombined,
  faRuler,
  faRunning,
  faSatelliteDish,
  faSchool,
  faScrewdriver,
  faSearchDollar,
  faSearchLocation,
  faSearchPlus,
  faSearch,
  faSeedling,
  faShare,
  faShieldAlt,
  faShip,
  faShippingFast,
  faShoePrints,
  faShoppingBag,
  faShoppingBasket,
  faShoppingCart,
  faShower,
  faShuttleVan,
  faSink,
  faSkating,
  faSkiingNordic,
  faSkiing,
  faSkullCrossbones,
  faSleigh,
  faSmileBeam,
  faSmileWink,
  faSmile,
  faSmokingBan,
  faSnowboarding,
  faSnowflake,
  faSnowman,
  faSoap,
  faSolarPanel,
  faSortAmountUp,
  faSpa,
  faSplotch,
  faSprayCan,
  faSquareFull,
  faSquare,
  faStarOfLife,
  faStar,
  faStepBackward,
  faStickyNote,
  faStopCircle,
  faStop,
  faStore,
  faStreetView,
  faSuitcaseRolling,
  faSuitcase,
  faSun,
  faSwimmer,
  faSwimmingPool,
  faSynagogue,
  faSync,
  faTableTennis,
  faTable,
  faTachometerAlt,
  faTag,
  faTemperatureHigh,
  faTemperatureLow,
  faTheaterMasks,
  faThermometerFull,
  faThermometerHalf,
  faThermometerQuarter,
  faThermometerThreeQuarters,
  faThumbsDown,
  faThumbsUp,
  faTicketAlt,
  faTimes,
  faTint,
  faToiletPaper,
  faToilet,
  faTools,
  faToriiGate,
  faTractor,
  faTrafficLight,
  faTrailer,
  faTrain,
  faTrashAlt,
  faTrash,
  faTree,
  faTrophy,
  faTruckMonster,
  faTruckMoving,
  faTruckPickup,
  faTruck,
  faTshirt,
  faTv,
  faUmbrellaBeach,
  faUnlockAlt,
  faUnlock,
  faUserAlt,
  faUserCheck,
  faUserClock,
  faUserCog,
  faUserFriends,
  faUserNurse,
  faUserPlus,
  faUser,
  faUsers,
  faUtensilSpoon,
  faUtensils,
  faVectorSquare,
  faVial,
  faVideo,
  faViruses,
  faVolleyballBall,
  faVoteYea,
  faWalking,
  faWallet,
  faWarehouse,
  faWater,
  faWeightHanging,
  faWheelchair,
  faWifi,
  faWind,
  faWindowClose,
  faWineBottle,
  faWineGlassAlt,
  faWineGlass,
  faYinYang,
  faAccessibleIcon,
  faAccusoft,
  faAdversal,
  faAndroid,
  faAngellist,
  faAppStoreIos,
  faAppStore,
  faApple,
  faAtlassian,
  faBattleNet,
  faBitbucket,
  faBluetooth,
  faCanadianMapleLeaf,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faCentercode,
  faCentos,
  faChromecast,
  faCreativeCommonsBy,
  faDeezer,
  faDocker,
  faDribbble,
  faEarlybirds,
  faEnvira,
  faEvernote,
  faFacebookF,
  faFacebookSquare,
  faFacebook,
  faFly,
  faFortAwesomeAlt,
  faFortAwesome,
  faForumbee,
  faGithubAlt,
  faGooglePlay,
  faGoogle,
  faGripfire,
  faHotjar,
  faInstagramSquare,
  faInstagram,
  faJira,
  faLeanpub,
  faLinkedin,
  faMailchimp,
  faMedium,
  faMicroblog,
  faPagelines,
  faPlaystation,
  faRaspberryPi,
  faReadme,
  faSafari,
  faShopify,
  faSimplybuilt,
  faSlideshare,
  faSourcetree,
  faStackExchange,
  faSuse,
  faTiktok,
  faUnity,
  faWhatsapp,
  faWolfPackBattalion,
  faWpforms,
  faYoutube,
  farAddressBook,
  farAddressCard,
  farArrowAltCircleDown,
  farArrowAltCircleLeft,
  farArrowAltCircleRight,
  farBell,
  farBuilding,
  farCalendarAlt,
  farCalendarCheck,
  farCalendarMinus,
  farCalendarPlus,
  farCalendarTimes,
  farCalendar,
  farChartBar,
  farCheckCircle,
  farCheckSquare,
  farClipboard,
  farClock,
  farCommentDots,
  farComments,
  farCompass,
  farCopyright,
  farDotCircle,
  farEdit,
  farEnvelopeOpen,
  farEnvelope,
  farEye,
  farFileAlt,
  farFileImage,
  farFilePdf,
  farFile,
  farFlag,
  farFutbol,
  farGrinAlt,
  farGrinBeam,
  farGrinHearts,
  farGrinSquint,
  farGrinStars,
  farGrin,
  farHandPointLeft,
  farHandPointRight,
  farHandPointUp,
  farHandPointer,
  farHandRock,
  farHandshake,
  farHeart,
  farIdCard,
  farImage,
  farImages,
  farKissWinkHeart,
  farLaughBeam,
  farLaughSquint,
  farLaughWink,
  farLaugh,
  farLemon,
  farLifeRing,
  farLightbulb,
  farListAlt,
  farMap,
  farMoneyBillAlt,
  farMoon,
  farNewspaper,
  farObjectUngroup,
  farPaperPlane,
  farQuestionCircle,
  farSmileBeam,
  farSmileWink,
  farSmile,
  farSnowflake,
  farSquare,
  farStar,
  farStopCircle,
  farSun,
  farThumbsUp,
  farTrashAlt,
  farUser,
);
