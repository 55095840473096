import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faVimeoSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faAlignLeft,
  faArrowsAltV,
  faBars,
  faBellSlash,
  faBuilding,
  faBullhorn,
  faCalendarAlt,
  faCaretDown,
  faCaretSquareDown,
  faCheckSquare,
  faCircleNotch,
  faCirclePlus,
  faClone,
  faCoins,
  faCube,
  faDiceD6,
  faDownload,
  faEuroSign,
  faEyeSlash,
  faFile,
  faFileImage,
  faFolder,
  faFont,
  faGlobe,
  faGripVertical,
  faHeading,
  faHome,
  faImage,
  faImages,
  faInfo,
  faKey,
  faLanguage,
  faLock,
  faMapMarker,
  faMapMarkerAlt,
  faMapPin,
  faObjectGroup,
  faPaintBrush,
  faPaperclip,
  faPen,
  faPlay,
  faPlusSquare,
  faQuestion,
  faRightFromBracket,
  faRotateLeft,
  faRss,
  faSave,
  faSearch,
  faShapes,
  faShoePrints,
  faSitemap,
  faSync,
  faTag,
  faTh,
  faThumbsDown,
  faThumbsUp,
  faToggleOff,
  faToggleOn,
  faTree,
  faUpDownLeftRight,
  faUpload,
  faVideo,
  faWindowRestore,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAlignLeft,
  faArrowsAltV,
  faBars,
  faBellSlash,
  faBuilding,
  faBullhorn,
  faCalendarAlt,
  faCaretDown,
  faCaretSquareDown,
  faCheckSquare,
  faCircleNotch,
  faCirclePlus,
  faClone,
  faCoins,
  faCube,
  faDiceD6,
  faDownload,
  faEuroSign,
  faEyeSlash,
  faFacebookF,
  faFile,
  faFileImage,
  faFolder,
  faFont,
  faGlobe,
  faGripVertical,
  faHeading,
  faHome,
  faImage,
  faImages,
  faInfo,
  faKey,
  faLanguage,
  faLock,
  faMapMarker,
  faMapMarkerAlt,
  faMapPin,
  faObjectGroup,
  faPaintBrush,
  faPaperclip,
  faPen,
  faPlay,
  faPlusSquare,
  faQuestion,
  faRightFromBracket,
  faRotateLeft,
  faRss,
  faSave,
  faSearch,
  faShapes,
  faShoePrints,
  faSitemap,
  faSync,
  faTag,
  faTh,
  faThumbsDown,
  faThumbsUp,
  faToggleOff,
  faToggleOn,
  faTree,
  faUpDownLeftRight,
  faUpload,
  faVideo,
  faVimeoSquare,
  faWindowRestore,
  faYoutubeSquare,
);
